import React from "react";
import SideBar from "./SideBar.js";
import Body from "./Body.js";
import Nav from "./Nav.js";
import "../style/App.scss";
import "../style/Desktop.scss";
import "../style/Mobile.scss";

export default function home(props) {
  return (
    <main>
      <SideBar />
      <Body />
      <Nav />
    </main>
  );
}
