import React from "react";
import { useForm } from "react-hook-form";

export default function Form() {
  const { register, errors } = useForm();
  return (
    <form
      action='https://formsubmit.co/leshan@leshanpatterson.com'
      method='POST'>
      <input
        type='hiden'
        name='_subject'
        value='New Submission From leshanpatterson.com'
        style={{ display: `none` }}
      />
      <input type='text' name='_honey' style={{ display: `none` }} />
      <div>
        <span></span>
        <input type='text' name='name' required placeholder='Name' />
      </div>
      <div>
        <span></span>
        <input type='email' name='email' required placeholder='Email' />
      </div>
      <div>
        <span></span>
        <input type='textarea' name='message' required placeholder='Message' />
      </div>
      <button>Send</button>
    </form>
  );
}
