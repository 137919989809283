import React from "react";
import "../style/Desktop.scss";
import "../style/Mobile.scss";
import { Age } from "../helper/helper.js";
import ProfilePic from "../images/profile_pic.jpg";
import CV from "../images/Leshan Patterson Resume.pdf";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faTwitter,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export default function SideBar(props) {
  return (
    <section className='side-bar'>
      <div className='side-bar__container'>
        <div className='side-bar__container__firstDiv'>
          <img src={ProfilePic} alt='' />
          <h5>Leshan Patterson</h5>
          <div>Front-End Developer</div>
        </div>
        <div className='side-bar__container__scroll'>
          <div className='side-bar__container__secondDiv'>
            <div>
              <h6>Residence:</h6>
              <span>Canada</span>
            </div>
            <div>
              <h6>City:</h6>
              <span>Toronto</span>
            </div>
            <div>
              <h6>Age:</h6>
              <span>{Age()}</span>
            </div>
          </div>
          <div className='side-bar__container__thirdDiv'>
            <div>
              <div>
                <h6>Fluent</h6>
              </div>
              <span>English</span>
            </div>
          </div>
          <div className='side-bar__container__fourthDiv'>
            <label>Front-End Development Skills</label>
            <div>
              <span>React</span>
              <span>JavaScript</span>
            </div>
            <div>
              <span>HTML</span>
              <span>jQuery</span>
            </div>
            <div>
              <span>SASS</span>
              <span>LESS</span>
            </div>
            <label>Backend-End Development Skills</label>
            <div>
              <span>Node.js</span>
              <span>Express</span>
            </div>
            <div>
              <span>C#</span>
              <span>ASP.Net</span>
            </div>
            <div>
              <span>REST API</span>
            </div>
            <label>Database Development Skills</label>
            <div>
              <span>SQL</span>
              <span>PostgreSQL</span>
            </div>
            <div>
              <span>Microsoft Azure</span>
              <span>EF CORE</span>
            </div>
            <div>
              <span>PHP</span>
            </div>
            <label>Terminal Skills</label>
            <div>
              <span>Git</span>
              <span>GitHub</span>
            </div>
            <label>CMS Skills</label>
            <div>
              <span>Photshop/Illustrator</span>
              <span>Adobe XD</span>
            </div>
            <div>
              <span>WordPress</span>
              <span>Shopify</span>
            </div>
            <div>
              <span>Craft CMS</span>
            </div>
          </div>
          <div className='side-bar__container__fifthDiv'>
            <div>
              <a href={CV} target='_blank' rel='noreferrer'>
                Download CV{" "}
                <FontAwesomeIcon className='sideBarIcons' icon={faDownload} />
              </a>
            </div>
          </div>
        </div>
        <div className='side-bar__container__sixthDiv'>
          <a
            href='https://github.com/lpattersonn?tab=repositories'
            target='_blank'
            rel='noreferrer'>
            <FontAwesomeIcon className='sideBarIcons' icon={faGithub} />
          </a>
          <a
            href='https://www.linkedin.com/in/lpattersonn/?originalSubdomain=ca'
            target='_blank'
            rel='noreferrer'>
            <FontAwesomeIcon className='sideBarIcons' icon={faLinkedin} />
          </a>
          <a
            href='https://twitter.com/lpattersonnn'
            target='_blank'
            rel='noreferrer'>
            <FontAwesomeIcon className='sideBarIcons' icon={faTwitter} />
          </a>
          <a
            href='https://medium.com/@lpattersonn'
            target='_blank'
            rel='noreferrer'>
            <FontAwesomeIcon className='sideBarIcons' icon={faMedium} />
          </a>
        </div>
      </div>
    </section>
  );
}
