import { React, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faGithub,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faDownload, faBars, faX } from "@fortawesome/free-solid-svg-icons";

export default function Nav(props) {
  let [navOpenStyle, setNavOpenStyle] = useState("open");
  let [navCloseStyle, setNavCloseStyle] = useState("close");

  return (
    <header>
      <FontAwesomeIcon
        className={navOpenStyle}
        onClick={() => {
          setNavCloseStyle("open");
          setNavOpenStyle("close");
        }}
        icon={faBars}
      />
      <nav className={navCloseStyle}>
        <FontAwesomeIcon
          className={navCloseStyle}
          id='nav-icon__close'
          onClick={() => {
            setNavCloseStyle("close");
            setNavOpenStyle("open");
          }}
          icon={faX}
        />
        <ul>
          <a href='#about'>
            <li>About</li>
          </a>
          <a href='#experience'>
            <li>Work Experience</li>
          </a>
          <a href='#projects'>
            <li>Projects</li>
          </a>
          <a href='#contact'>
            <li>Get In Touch</li>
          </a>
        </ul>
      </nav>
    </header>
  );
}
